/* Global */

html,body {
    /* border-box box model allows us to add padding and border to our elements without increasing their size */
    box-sizing: border-box;
    width: 100%;
    margin: 0px;
    padding: 0px;
}
img {
    max-width: 100%;
    max-height: auto;
}
p {
    text-align: justify;
  }

li {
    text-decoration: none;
}
  a {
    color:grey;
  }



/* Header */
.header {
    grid-area: header;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.logoimage {
    padding: 10px 0 10px 20px;
}

.logotext {
    margin-right: auto;
    padding-left: 20px;
}

.phone {
    grid-area: phone;
    font-family: 'Lora', serif;
    font-size: 16px;
}


/* Top Navigation */
.topnav {
    grid-area: topnav;
    display: flex;
    justify-content: space-around;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: black;
}

/* Style the links inside the navigation bar */
.topnav a {
    float: left;
    color: #000000;
    text-align: center;
    padding: 12px 14px;
    text-decoration: none;
    font-size: 16px;
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add a color to the active/current link */
  .topnav a.active {
    color: grey;
  }

  /* Add an active class to highlight the current page */
.active {
    background-color: #4CAF50;
    color: white;
  }
  
  /* Hide the link that should open and close the topnav on small screens */
  .header .icon {
    display: none;

  }
  .iconclose {
      color:white;
  }
  
  #topnavoverlay {
    display: none;
    text-shadow: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 200px;
    padding: 10px;
    background: #222;
    color: whitesmoke;
    overflow-y: auto;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}
.iconclose {
    align-self: right;
}
.topnavoverlayul {
    padding: 10px;
} 




/* Footer */
.footer {
    grid-area: footer;
    display:grid;
    grid-template-rows: auto auto;
    background-color:  black;  
    align-items: center; 
}
.copyright {
    grid-row: 1 / 2;
    padding-left: 10px;
}  
.phonefooter {
    grid-row: 1 / 2;
    justify-self: end;
}
.socialmediaiconsfooter{
    grid-row: 1 / 2;
    justify-self: end;
    align-self: center;
    display: flex;
    flex-direction: row;
}
.brahmaforces {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    justify-self: center;
    color: whitesmoke;
}

/* Blockquote */
blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
  }
  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  blockquote:before {
    color: #ccc;
    content: close-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

/* Inside Pages */
.pagecontainer {
    display: grid;
    grid-template-columns: 15% auto 15%;

}
.pagetitle {
    grid-column: 2 / 3;
    justify-self:center;
}
.pageheroimage {
    grid-column: 1 / -1;
    object-fit: cover;
    width: 100%;
    height: 100vh;

}
.pagetext {
    grid-column: 2 / 3;
    font-family: 'Cardo', serif;
    font-size: 28px;
}
 
/* Portfolio */
.portfoliocontainer{
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 15% auto 15%;
}
.portfolioimage {
    grid-column: 1 / -1;
}
.portfolioimagemob {
    grid-column: 2 / 3;
}
.portfolioimageimg {
    width: 100%;
}
.portfoliotitlemain{
    grid-column: 2 / 3;
    justify-self: center;
}

/* Hero image */
.heroimage{
    grid-column: 1 / -1;
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
}

.heroimageimg {
    object-fit: cover;
    width: 100%;
    height: 100vh;
}



/* On Non-mobile screens - remove the overlay menu */
@media only screen and (min-width: 600px) {

    #topnavoverlay {
        display: none;
        }
    }
    
/* On mobile screens */

@media only screen and (max-width: 600px) {
        
        .topnav a {
            display: none;
        }
        .topnav {
            justify-content: space;
        }
    
        .header {
            align-items: center;
            
        }
        .header .icon{
            display: block;
        }
        .facebook, .instagram, .twitter, .linkedin {
            display: none;
        }
        .phone {
            display: none;
        }
        .logotext {
            margin-right: inherit;
        }
        .header {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            justify-items: center;
        }
        .logoimage{
            grid-column: 1 / -1;
            grid-row: 1 / 2;
            padding: 0px;
            justify-self: center;
        }
        .logotext {
            grid-row: 2 / 3;
            display: grid;
            grid-template-rows: auto auto;
            padding: 0px;

        }
        .icon {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
            justify-self: left;
            padding-left: 15px;
        }
        .logoactualimage{
            width:75%;
        }
        .logoactualtext{
            width:75%;
            justify-self: center;
        }
        .footer {
            grid-area: footer;
            display:grid;
            grid-template-rows: 100px 100px 100px 100px;
            grid-template-rows: 1fr;
            background-color:  black;  
            align-items: center;
            justify-content:  center;
        }
        .copyright {
            grid-row: 1 / 2;
            justify-self: center;
        }  
        .phonefooter {
            grid-row: 2 / 3;
            justify-self: center;
        }
        .socialmediaiconsfooter{
            grid-row: 3 / 4;
            justify-self: center;
            align-self: center;
            display: flex;
            flex-direction: row;

        }
        .brahmaforces {
            grid-row: 4 / 5;
            grid-column: 1 / -1;
            justify-self: center;
            color: whitesmoke;
        }
        .sectionhead {
            font-size: 24px;
        }
        .sectionheading {
            font-size: 18px;
        }
        .sectionsubheading {
            font-size: 14px;
        }
        #aboutcustom {
            display: grid;
            grid-template-columns: 1fr;
            padding-top: 20px;
        }
        .aboutphoto {
          grid-column: 1 / -1;
          grid-row: 1 / 2;
          padding-top: 20px;
          margin-top: 20px;
        }
        .abouttext {
            grid-column: 1 / -1;
            grid-row: 2 / 3;
        }

      }
